body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

@import url("https://cdn.staticfile.net/bootstrap-icons/1.7.2/font/bootstrap-icons.min.css");
@import url("https://cdn.staticfile.net/bootstrap/4.6.1/css/bootstrap.min.css");

$blue: rgba(0, 160, 233);

body {
  color: #666;
  background: #f6f7fa;
}
a {
  color: #666;
}
a:hover {
  opacity: .6;
  text-decoration: none;
}

.avatar {
  width: 100%;
  height: 100%;
  position: relative;
  img {
    border: 1px solid #ddd;
    left: 0;
    top: 0;
    position: absolute;
    width: 100%;
    border-radius: 50%;
    background: #eee;
    overflow: hidden;
  }
}

.header {
  display: flex;
  padding: 1.25rem 0;
  line-height: 2rem;
  font-size: 1.25rem;
  .logo {
    height: 2rem;
  }
  .disabled { color: #ddd; cursor: default }
  .menu {
    margin-left: 1.5rem;
    display: flex;
    margin-top: 1rem;
    .menu-item {
      padding: 0 1.5rem;
      font-weight: 500;
    }
  }
  .userInfo {
    padding-left: 3rem;
    .avatar {
      width: 2rem;
      height: 2rem;
      float: left;
      margin-right: .5rem;
      cursor: pointer;
    }
    .logout {
      margin-left: .5rem;
      cursor: pointer;
      &:hover {
        opacity: .6;
      }
    }
  }
  .profile {
    margin-top: 1rem;
    margin-left: auto;
    .profile-menu {
      display: flex;
      .menu-item {
        width: 3.75rem;
        text-align: center;
        cursor: pointer;
      }
    }
  }
}

.captcha {
  display: flex;
  flex-wrap: wrap;
  .form-label {
    width: 100%;
    display: block;
  }
  .form-control {
    width: 50%;
  }
}

.form-group, .input-group {
  .alert {
    padding:.3rem .5rem;
    margin-top: .5rem;
    flex:100% 0;
  }
  .captcha-img {
    width: 100%;
    cursor: pointer;
    img {
      height: calc(100% - 2px);
    }
  }
}

.copyright {
  text-align: center;
  padding: 3em 0 3rem 0;
  font-size: 1rem
}

/* width */
::-webkit-scrollbar {
  width: .5rem;
}

/* Track */
::-webkit-scrollbar-track {
  background: #fff;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

#loading {
  position: fixed;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 9999;
  background: #99999920
}

#toastList {
  z-index: 10000;
  position: fixed;
  left: auto;
  right: 2rem;
  top: 1.8rem;
  .toast {
    min-width: 16rem;
    .toast-header {
      i { margin-right: .5rem}
    }
  }
}